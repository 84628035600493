<template>
  <div class="form-div">
      <Form class="isForm" label-position="right" :label-width="120">
        <FormItem class="isWidth">
              <span slot="label" class="validate">所属社区:</span>
              <Cascader
                v-model="formData.orgCode"
                :data="publishList"
                :load-data="loadCommunity"
                change-on-select
                @on-change="changeCascader"
                :disabled="$route.query.isEdit != 'false'"
              ></Cascader>
          </FormItem>
          <FormItem class="isWidth">
              <span slot="label" class="validate">姓名:</span>
              <Input placeholder="请输入您的姓名" :maxlength="20" v-model.trim="formData.name" @on-change="changeName" />
          </FormItem>
          <FormItem class="isWidth">
              <span slot="label">昵称:</span>
              <Input placeholder="请输入您的昵称" :maxlength="20" v-model="formData.nickname"  />
          </FormItem>
          <FormItem class="isWidth" >
              <span slot="label" class="validate">证件类型:</span>
              <Select clearable v-model="formData.idNumType" >
                <Option
                v-for="item in idType"
                :value="item.dictKey"
                :key="item.dictKey"
                >{{ item.dictValue }}</Option>
            </Select>
          </FormItem>
          <FormItem class="isWidth">
              <span slot="label" class="validate">证件号码:</span>
              <Input placeholder="请输入您的证件号码" :maxlength="18" v-model.trim="formData.idNum"  />
          </FormItem>
          <FormItem class="isWidth">
              <span slot="label">性别:</span>
              <Select clearable v-model="formData.sex" >
                <Option value="1">男</Option>
                <Option value="2">女</Option>
            </Select>
          </FormItem>
          <FormItem class="isWidth">
              <span slot="label">出生日期:</span>
               <DatePicker type="date" v-model="formData.birthday"></DatePicker>
          </FormItem>
          <FormItem class="isWidth">
              <span slot="label">民族:</span>
              <Select clearable v-model="formData.national" >
                <Option
                v-for="item in nationalType"
                :value="item.dictKey"
                :key="item.dictKey"
                >{{ item.dictValue }}</Option>
            </Select>
          </FormItem>
          <FormItem class="isWidth">
              <span slot="label">籍贯:</span>
              <Input placeholder="请输入您的籍贯" :maxlength="20" v-model="formData.hometown"  />
          </FormItem>
          <FormItem class="isWidth">
              <span slot="label">手机号码1:</span>
              <Input  placeholder="请输入您的手机号码" :maxlength="11" v-model="formData.mobileMaster"  />
          </FormItem>
          <FormItem class="isWidth">
              <span slot="label">手机号码2:</span>
              <Input placeholder="请输入您的手机号码" :maxlength="11" v-model="formData.mobileBackup"  />
          </FormItem>
          <FormItem class="isWidth">
              <span slot="label">固定电话1:</span>
              <Input placeholder="请输入您的固定电话" :maxlength="20" v-model="formData.telMaster"  />
          </FormItem>
          <FormItem class="isWidth">
              <span slot="label">固定电话2:</span>
              <Input placeholder="请输入您的固定电话" :maxlength="20" v-model="formData.telBackup"  />
          </FormItem>
          <FormItem class="isWidth">
              <span slot="label" class="validate">所属社区:</span>
              <Cascader
                v-model="formData.orgCode"
                :data="publishList"
                :load-data="loadCommunity"
                change-on-select
                @on-change="changeCascader"
                :disabled="$route.query.isEdit != 'false'"
              ></Cascader>
          </FormItem>
          <FormItem class="isWidth">
              <span slot="label">房号信息:</span>
               <Input placeholder="请输入您的房号信息" :maxlength="20" v-model="formData.houseAddr" enter-button="选择" search @on-search="searchRoom" @on-change="changeHouseAddr" />
          </FormItem>
          <FormItem class="isNoWidth">
              <span slot="label">户籍地址信息:</span>
              <Cascader
                v-model="formData.domicileOrgCode"
                :data="chinaList"
                :load-data="loadChinaCommunity"
                change-on-select
                @on-change="changeChianCascader"
              ></Cascader>
          </FormItem>
          <FormItem class="isNoWidth">
              <span slot="label">详细地址:</span>
              <Input placeholder="请输入您的详细地址" v-model="formData.domicileDetailAddr"  />
          </FormItem>
          <FormItem class="isNoWidth">
              <span slot="label">备注:</span>
              <Input type="textarea" placeholder="请输入您的备注" v-model="formData.remark"  />
          </FormItem>
      </Form>
       <LiefengModal
          title="选择房号信息"
          :value="addrStatus"
          @input="addrStatusFn"
          :fullscreen="false"
          width="30%"
          class="edit"
        
        >
          <template v-slot:contentarea>
              <Tree v-if="showTree" :code="treeCode" :name="treeName" @handleTree="handleTree"></Tree>
          </template>
          <template v-slot:toolsbar>
           <Button style="margin: 0 5px 0 13px" type="warning" @click="addrTip=true;addrStatusFn(false);">取消</Button>
          <Button style="margin: 0 5px 0 13px" type="primary" @click="addrSave">保存</Button>
        </template>
      </LiefengModal>
  </div>
</template>

<script>
import Tree from '../childrens/tree'
import LiefengModal from "@/components/LiefengModal";
export default {
    props:{
        formData:{
            type:Object,
            default:()=>{
                return {}
            }
        },
        userId:{
            type:Object,
            default:()=>{
                return ''
            }
        },
        familyId:{
            type:Object,
            default:()=>{
                return ''
            }
        }
    },
    components:{Tree,LiefengModal},
    data(){
        return{
            idType:[], //证件类型
            nationalType:[],//民族
            publishList: [],//所属社区
            // 模态框状态
            addrStatus:false, 
            addrTip:false,
            showTree:false,
            treeCode:'',
            treeName:'',
            selectTree:[],
            address:'',
            treeData:[],
            // 模态框结束
            chinaList:[],//全国地址的参数
            isTrue:true
        }
    },
    created(){
        this.getDicType('PERSONAL_TYPE','idType')
        this.getDicType('NATIONAKLITY','nationalType')
        this.getPulishData(4401, "publishList");
        this.getChina('CN','chinaList')
        this.getDetail()
    },
    methods:{
        // 异步加载中国的接口
        loadChinaCommunity(item,callback){
             item.loading = true;
            let arrCode = item.value;
            this.$get("/orgzz/pc/organization/selectCascadeDataScope", {
                    orgCode: arrCode,
                    custGlobalId:parent.vue.loginInfo.userinfo.custGlobalId,
                    oemCode:parent.vue.oemInfo.oemCode,
                    orgCodeSelf:parent.vue.loginInfo.userinfo.orgCode,
                }).then(res => {
                if (res.code == 200) {
                    if (item.orgLevel != '4') {
                        item.children = res.dataList.map(item => {
                        return {
                            value: item.orgCode,
                            label: item.orgName,
                            orgLevel: item.orgLevel,
                            loading: false,
                            children: [],
                            index: 1
                        };
                        });
                        if(this.formData.changeDomicArr && this.formData.changeDomicArr.length > 0) {
                            this.formData.domicileOrgCode = this.formData.domicileOrgCode.concat(this.formData.changeDomicArr[0]);
                            this.formData.changeDomicArr = this.formData.changeDomicArr.slice(1,this.formData.changeDomicArr.length);
                         }
                    } 
                    else {
                        item.children = res.dataList.map(item => {
                        return {
                            value: item.orgCode,
                            label: item.orgName,
                            orgLevel: item.orgLevel,
                            index: 1
                        };
                        });
                        if(this.formData.changeDomicArr && this.formData.changeDomicArr.length > 0) {
                            this.formData.domicileOrgCode = this.formData.domicileOrgCode.concat(this.formData.changeDomicArr[0]);
                            this.formData.changeDomicArr = this.formData.changeDomicArr.slice(1,this.formData.changeDomicArr.length);
                         }
                    }
                        item.loading = false;
                        callback();
                    }
            });
        },
        // 改变中国选项
        changeChianCascader(val,value){
        },
        // 树结构保存按钮
        addrSave(){
            if(this.selectTree && this.selectTree.length ==0){
            //  if(this.dataScopeList.length ==0){
                this.$Message.warning({
                content:'请选择房号信息',
                background:true
              })
              return
            //  }
          }else{
            if(this.selectTree[0].regionType != '0' && this.selectTree[0].regionType != '4'){
                this.$Message.warning({
                content:'现住地址请选择到房号',
                background:true
                })
                return
             }else{  
               this.formData.houseAddr = this.address
              this.formData.houseCode = this.selectTree[0].code
              this.addrStatus = false
              this.showTree = false
              this.addrTip =false
              
            }
          }
           
        },
        searchRoom(){    
            if(!this.formData.orgCode || this.formData.orgCode == '' || this.formData.orgCode.length !=3){
                this.$Message.warning({
                    content:'请先选择所属社区',
                    background:true
                })
                return
            }
            this.treeCode = ''
            this.treeCode = this.formData.orgCode[this.formData.orgCode.length - 1]
            this.addrStatus = true
            this.$nextTick(()=>{
                this.showTree = true
            })   
             
        },
         // 点击了树节点的东西
        handleTree(val,treeData){
          this.selectTree = val 
          this.treeData = []
          this.treeData = treeData;
          this.address = '';
          this.getCurrentTitle(this.selectTree[0].id,treeData);
        },
        // 如果房号input框改变了
        changeHouseAddr(){
            this.formData.houseCode = ''
        },
            //递归面包屑
        getCurrentTitle(id, treeData) {
            for(let k in treeData) {
                if(treeData[k].id == id) {
                this.address = (treeData[k].name || treeData[k].orgName) + this.address;
                if(!treeData[k].parentId) return;
                this.getCurrentTitle(treeData[k].parentId,this.treeData)
                }else if(treeData[k].children && treeData[k].children.length > 0){
                this.getCurrentTitle(id,treeData[k].children)
                }
            }
        },
         // 保存按钮
        addrStatusFn(status){
          if(!status && this.addrTip){
            this.addrStatus = status
            this.showTree = false
            this.addrTip =false
            this.treeCode = ''
          }else{
            this.addrStatus = status
            this.showTree = false
            this.addrTip =false
          }
        },
        // 级联选择异步选择
        loadCommunity(item, callback) {
            item.loading = true;
            let arrCode = item.value;
            this.$get("/gx/pc/organization/selectOrganizationByOrgCode", {
                orgCode: arrCode,
            }).then(res => {
                if (res.code == 200) {
                if (!item.index) {
                    item.children = res.dataList.map(item => {
                    return {
                        value: item.orgCode,
                        label: item.orgName,
                        orgLevel: item.orgLevel,
                        loading: false,
                        children: [],
                        index: 1
                    };
                    });
                    if(this.formData.changeOrgArr && this.formData.changeOrgArr.length > 0) {
                        this.formData.orgCode = this.formData.orgCode.concat(this.formData.changeOrgArr[0]);
                        this.formData.changeOrgArr = this.formData.changeOrgArr.slice(1,this.formData.changeOrgArr.length);
                    }
                } else {
                    item.children = res.dataList.map(item => {
                    return {
                        value: item.orgCode,
                        label: item.orgName,
                        orgLevel: item.orgLevel,
                        index: 1
                    };
                    });
                     if(this.formData.changeOrgArr && this.formData.changeOrgArr.length > 0) {
                        this.formData.orgCode = this.formData.orgCode.concat(this.formData.changeOrgArr[0]);
                        this.formData.changeOrgArr = this.formData.changeOrgArr.slice(1,this.formData.changeOrgArr.length);
                    }
                }
                item.loading = false;
                callback();
                }
            });
            },
        // 选择所属社区回调事件
        changeCascader(val,value){   
            
        },
        // 名字改变之后的回调事件
        changeName(){
            this.$emit('changeName',this.formData.name)
        },
        // 获取字典接口的公共方法
        getDicType(type,model){
            this.$get('/voteapi/api/common/sys/findDictByDictType',{
                dictType:type
            }).then(res=>{
                this[model] = res.dataList
            })
        },
        // 获取区
        getPulishData(code, list) {
            this.$get("/orgzz/pc/organization/selectCascadeDataScope", { 
                custGlobalId:parent.vue.loginInfo.userinfo.custGlobalId,
                oemCode:parent.vue.oemInfo.oemCode,
                orgCodeSelf:parent.vue.loginInfo.userinfo.orgCode,
                orgCode: code,
                }).then(res => {
                    if (res.code == 200) {
                    this[list] = res.dataList.map(item => {
                        return {
                        value: item.orgCode,
                        label: item.orgName,
                        orgLevel: item.orgLevel,
                        loading: false,
                        children: []
                        };
                    });
                    }
                });
        },
        // 获取全国范围的
        getChina(code,list){
             this.$get("/orgzz/pc/organization/selectCascadeDataScope", { 
                custGlobalId:parent.vue.loginInfo.userinfo.custGlobalId,
                oemCode:parent.vue.oemInfo.oemCode,
                orgCodeSelf:parent.vue.loginInfo.userinfo.orgCode,
                orgCode: code,
                }).then(res => {
                    if (res.code == 200) {
                    this[list] = res.dataList.map(item => {
                        return {
                        value: item.orgCode,
                        label: item.orgName,
                        orgLevel: item.orgLevel,
                        loading: false,
                        children: []
                        };
                    });
                    }
                });
        },
        // 获取人员基本信息
        getDetail(){
            if(this.userId && this.userId != ''){
                this.$get('/syaa/api/syuser/pc/residentArchive/selectUserDetail',{
                    userId:this.userId,
                    familyId:this.familyId
                    }).then(res=>{
                        let data = {}
                        data = res.data
                        if(data.orgPath && data.orgPath != ''){
                            data.orgPath = data.orgPath.split(',')
                            data.orgCode = [data.orgPath[2]];
                            data.changeDomicArr = []
                            data.changeOrgArr = data.orgPath.slice(3,5);
                        }
                        if(data.domicileOrgPath && data.domicileOrgPath != ''){
                            data.domicileOrgPath = data.domicileOrgPath.split(',')
                            data.domicileOrgCode = [data.domicileOrgPath[0]]
                            data.changeDomicArr = []
                            data.changeDomicArr = data.domicileOrgPath.slice(1,5)
                        }
                        // console.log(data);
                        this.$emit('getDetail',data)
                    })
            }
            
        }
    },
}
</script>

<style lang="less" scoped>
.form-div{
    width:100%;
    .isForm{
        display: flex;
        flex-wrap: wrap;
        .isWidth{
        width: 40%;
        margin-right: 40px;
        }
        .isNoWidth{
            width: 100%;
        }
    }
    
}
/deep/.validate {
  &::before {
    content: "*";
    display: inline-block;
    margin-right: 4px;
    line-height: 1;
    font-family: SimSun;
    font-size: 14px;
    color: #ed4014;
  }
}
/deep/.ivu-date-picker{
    display: inline;
}
</style>